<template>
  <!---------- Details collapse start---------------->
  <div class="flightdetails_innerbox">
    <h4>{{$t("sabre.search-result.box.flight-details")}}</h4>

    <div class="flightdetails_item">

      <div class="titlebox">
        <span><img src="/assets/sabre-img/airplane.png"></span>
        {{$t("sabre.search-result.box.go-to", {dest: arrivalCityName})}}
      </div>

      <div v-for="(segment, inx) in data.legs[0].segmentExcerpts" :key="inx">
        <collapse-one-item :data="segment"
          :department="data.legs[0].excerpt.cabinCode"
          :checkinBaggage="data.legs[0].excerpt.checkinBaggageAllowance"
          :fcFlightAdditionalInfo="data.legs[0].excerpt.fcFlightAdditionalInfo"
          :fcFlight="fcFlight"/>
        <h3 v-if="inx !== data.legs[0].excerpt.connections">
          <strong>{{$t("sabre.search-result.box.stop-in", {dest: getDestCityName(segment.arrival.city)})}}</strong>
          <!-- {{$t("sabre.search-result.box.flight-hour-minute", getElapsedTime(segment.elapsedTime))}} -->
          {{$t("sabre.search-result.box.flight-hour-minute", getStopTime(segment, data.legs[0].segmentExcerpts[inx + 1]))}}
        </h3>
      </div>

    </div>

    <div class="flightdetails_item" v-if="kind!=='one-way'">

      <div class="titlebox">
        <span><img src="/assets/sabre-img/airplane1.png"></span>
        {{$t("sabre.search-result.box.return-to", {dest: departureCityName})}}
      </div>

      <div v-for="(segment, inx) in data.legs[1].segmentExcerpts" :key="inx">
        <collapse-one-item :data="segment"
          :department="data.legs[1].excerpt.cabinCode"
          :checkinBaggage="data.legs[1].excerpt.checkinBaggageAllowance"
          :fcFlightAdditionalInfo="data.legs[1].excerpt.fcFlightAdditionalInfo"
          :fcFlight="fcFlight"/>
        <h3 v-if="inx !== data.legs[1].excerpt.connections">
          <strong>{{$t("sabre.search-result.box.stop-in", {dest: getDestCityName(segment.arrival.city || segment.arrival.airport)})}}</strong>
          <!-- {{$t("sabre.search-result.box.flight-hour-minute", getElapsedTime(segment.elapsedTime))}} -->
          {{$t("sabre.search-result.box.flight-hour-minute", getStopTime(segment, data.legs[1].segmentExcerpts[inx + 1]))}}
        </h3>
      </div>

    </div>
  </div>
  <!---------- Details collapse End---------------->
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';

export default {
  props: {
    data: {
      type: Object,
      default: null,
    },
    kind: {
      type: String,
      default: '',
    },
    fcFlight: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CollapseOneItem: () => import('./collapseOneItem'),
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      destinationList: 'GET_DESTINATION_IMAGES',
      airlineCompanies: 'GET_SABRE_AIRLINE_COMPANIES',
      airports: 'GET_SABRE_AIRPORTS',
    }),
    destinations() {
      const { destinationList } = this;
      // return destinationList?.filter((dest) => dest.forSabreFlight) || [];
      return destinationList?.filter((dest) => dest.name) || [];
    },
    departureCityCode() {
      const { data } = this;
      return data.legs[0].excerpt.departure.city;
    },
    departureCityName() {
      const { destinations, departureCityCode } = this;
      return destinations.find((d) => d.code === departureCityCode)?.name_Loc?.content || '';
    },
    // departureCityEnName() {
    //   const { destinations, departureCityCode } = this;
    //   return destinations.find((d) => d.code === departureCityCode)?.name || '';
    // },
    arrivalCityCode() {
      const { data } = this;
      return data.legs[0].excerpt.arrival.city;
    },
    arrivalCityName() {
      const { destinations, arrivalCityCode } = this;
      return destinations.find((d) => d.code === arrivalCityCode)?.name_Loc?.content || '';
    },
    // arrivalCityEnName() {
    //   const { destinations, arrivalCityCode } = this;
    //   return destinations.find((d) => d.code === arrivalCityCode)?.name || '';
    // },
    elapsedTime() {
      const { data } = this;
      const et = data.legs[0].excerpt.elapsedTime;
      return { hour: Math.floor(et / 60), minute: et % 60 };
    },
  },
  methods: {
    getDestCityName(cityCode) {
      const { destinations } = this;
      const one = destinations.find((d) => d.code === cityCode);
      return one?.name || one?.name_Loc?.content || cityCode;
    },
    getElapsedTime(time) {
      return { hour: Math.floor(time / 60), minute: time % 60 };
    },
    getStopTime(dSeg, aSeg) {
      const departure = dayjs(new Date(`${dSeg.departureDate} ${dSeg.arrival.time}`)),
        arrival = dayjs(new Date(`${aSeg.departureDate} ${aSeg.departure.time}`));
      const durationTime = arrival.diff(departure, 'minutes', true);
      return { hour: Math.floor(durationTime / 60), minute: durationTime % 60 };
    },
  },
};
</script>
